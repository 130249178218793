<template>
  <div class="auth-container">
    <div class="modal-login-title">Номер телефона</div>
    <div class="modal-login-subtitle">Нужен для кода подтверждения</div>
    <v-text-field
      ref="phone_input"
      v-model="phone"
      v-maska="['+# (###) ###-##-##', '+### (##) ###-##-##']"
      @input="phoneError = ''"
      hide-details="auto"
      :error-messages="phoneError"
      placeholder="+_(___) ___ __ __"
      label="Номер телефона"
      persistent-placeholder
      class="auth-input mb-7"
      height="50"
      @focus="onFocus"
    />
    <div class="hint-text mb-4">Если ваш номер телефона начинается не с +7, воспользуйтесь входом через email</div>
    <div class="transparent-button mb-4" @click="toLoginEmail">Войти через email</div>
    <v-btn width="100%" class="mb-6" color="primary" :loading="loginPhoneButtonLoading" @click="next" :disabled="!checkValue" depressed>Далее</v-btn>
    <LicenseAgreement :buttonText="buttonText"/>
  </div>
</template>

<script>
import LicenseAgreement from '@/views/login/LicenseAgreement.vue';

export default {
  components: {
    LicenseAgreement,
  },
  name: 'LoginPhone',
  data: () => ({
    phone: '',
    buttonText: 'Далее',
    loginPhoneButtonLoading: false,
    phoneError: ''
  }),
  methods: {
    toLoginEmail() {
      this.$router.push({
        path: '/login/email',
        query: {
          role: 'hr',
          url: this.$route.query.url || '',
        },
      });
    },
    next() {
      if (this.checkValue) {
        const phone = this.phone;
        if (phone[1] !== '7') {
          this.phoneError = 'Воспользуйтесь входом через email'
          return;
        }
        this.loginPhoneButtonLoading = true;
        this.$server.request2('auth/login', { phone, role: 'hr' }, (data) => {
          const codeToken = data.response;
          this.$router.push({
            path: '/login/code',
            query: {
              url: this.$route.query.url || '',
              codeToken,
              phone,
            },
          });
        }, (data) => {
          this.phoneError = data.error;
        }, (data) => {
          this.loginPhoneButtonLoading = false;
        });
      }
    },
    onFocus() {
      if (!this.phone.length) {
        this.phone = '+7 ('
      }
    }
  },
  computed: {
    checkValue() {
      return this.phone.length === 18 || this.phone.length === 19;
    },
  },
  mounted() {
    this.$refs.phone_input.focus();
  }
};
</script>

<style lang="scss">
.v-application.theme--light .auth-container {
  .auth-input input, .auth-input input::placeholder {
    font-size: 22px;
    font-weight: bold;
  }
  .hint-text {
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: rgba(var(--page-color-main-rgb), 0.5);
    text-align: center;
  }
}

</style>

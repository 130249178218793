<template>
  <div class="agreement-text">
    <p>
      <span v-if="buttonText">Нажимая "{{ buttonText }}"</span>
      <span v-else>Продолжая</span>
      вы подтверждаете, что ознакомлены и соглашаетесь c
      <a class="external" target="_blank" :href="href1">
        условиями использования сервиса</a> и
      <a class="external" target="_blank" :href="href2">
        Политикой конфеденциальности.
      </a>
    </p>
  </div>
</template>

<script>
export default {
  name: 'LicenseAgreement',
  props: {
    buttonText: String,
  },
  data() {
    return {
      href1: 'https://rekroo.org/terms',
      href2: 'https://rekroo.org/privacy',
    };
  },
};
</script>
